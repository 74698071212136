import "echarts-liquidfill/src/liquidFill.js";
import * as echarts from "echarts"
import moment from "moment";
import Util from "@/utils/utils";
import { getOperatePlanReach, exportAllInfoList, reportUnionAll } from "./api";

export default {

  data() {
    return {
      activeNameStyle: "height: 66px; overflow: hidden;",
      showNameStyle: "min-height: 66px;",
      isShowNameType: true,
      activeNameStyle1: "height: 66px; overflow: hidden;",
      showNameStyle1: "min-height: 66px;",
      isShowNameType1: true,
      activeNameIndex: null,
      aticity: false,
      navList: [],
      breadcrumbData: [{
        path: "/index",
        name: "index",
        title: "首页"
      },
        {
          path: "",
          name: "/customer360",
          title: "运营商综合评价"
        }
      ],
      filForm: {},
      isLoading: false,
      tableData: [],
      zonghedefr: 0.0,
      pageLoadFlag: false,
      paiming: "",
      custnum:'--',
      currentPreviousDay:'',
      NetIDN:{},// 分销网络净增数据
      DSRDNetwork:{},// 分销网络动销率
      SingleStoreOutput:{}, //分销网络单店产出
      IncrementalBusiness:{},//增量业务
      PDedicatedAircraft:{},//专供机占比
      channelObj:{},// 全渠道
      PMHEnd:{}, // 中高端占比
      monishuju:[
        {
          name1:111,
          name2:121,
          name3:131,
        },
        {
          name1:222,
          name2:113331,
          name3:44,
        },
        {
          name1:444,
          name2:1311,
          name3:4,
        },
      ],
      customerNum:'',// 商家总数
      comprehensiveRanking:'', // 排名
      comprehensiveScore:0,// 综合得分
      pieData:[
         {value: 0, name: '全渠道口径出货(60) '},
          {value:0, name: '分销网拓展与维护(20)'},
          {value: 0, name: '增值业务(前置渠道)(5)'},
          {value: 0, name: '销售结构(15) '},

      ],// 饼图数据
    };
  },
  created() {
    this.filForm.date = moment(new Date()).format("YYYY-MM");
  },
  mounted() {
    this.getRepostData();
    this.getYesterday()


  },
  methods: {
    dateChange(e) {
      this.filForm.date = moment(e._d).format("YYYY-MM");
      this.getRepostData();

    },
    drawCharts() {
      var myChart = this.$echarts.init(this.$refs.fourth);
      var dataA = this.pieData
      this.$forceUpdate()
      let option =
        {
        color: [new echarts.graphic.LinearGradient(1, 1, 0, 0, [
          {
            offset: 0,
            color: '#8385E9'
          },
            {
              offset: 0.9,
              color: '#BFC1F7'
            }
            ]),
            new echarts.graphic.LinearGradient(1, 1, 0, 0, [{
            offset: 0,
            color: '#22C7BB'
          },
            {
              offset: 0.9,
              color: '#9DFDD5'
            }]),
            new echarts.graphic.LinearGradient(1, 1, 0, 0, [{
            offset: 0,
            color: '#F36556'
          },
            {
              offset: 0.9,
              color: '#FFAF9B'
            }]),
            new echarts.graphic.LinearGradient(1, 1, 0, 0, [{
              offset: 0,
              color: '#F69A52'
            },
              {
                offset: 0.9,
                color: '#F8CA6C'
              }])
          ],
          legend: {
            selectedMode: false,
            icon: "circle",
            left: "55%",
            y: "center", //延Y轴居中
            // x: '30%', //居右显示
            // orient: "vertical",
            color: "#ffffff",
            right: 20, //右边距0
            top: '50%',
            // icon: "pie",
            itemWidth: 8, // 设置宽度
            itemHeight: 8, // 设置高度
            // itemGap: this.$util.setFont(12), // 设置间距
            itemGap: 16, // 设置间距
            formatter: function(name) {
              var value;
              for (let i = 0, l = dataA.length; i < l; i++) {
                if (dataA[i].name == name) {
                  value = dataA[i].value;
                }
              }
              var str = "{a|" + name + "}{b|" + value + "}";
              return str;
            },
            textStyle: {
              rich: {
                a: {
                  color: "#777",
                  fontSize: 14,
                  width: 160,
                  padding: [0, 0, 0, 2] //间距
                },
                b: {
                  color: "#262626",
                  fontSize: 14,
                  // padding: [0, 0, 16, 2] //间距
                }
              }
            }
          },
          series: [
            {
              // hoverAnimation: false,
              name: '',
              type: 'pie',
              center: ['30%', '60%'],
              radius: ['49%', '62%'],
              avoidLabelOverlap: false,
              clockWise: false,
              itemStyle: {
                normal: {
                  borderColor: '#f7f7f7',
                  borderWidth: 4
                }
              },
              label: {
                show: false,
                position: 'center'
              },

              labelLine: {
                normal: {
                  show: false
                }
              },
              data: this.pieData
            },
            // 边框的设置
            {
              hoverAnimation: false,
              radius: ['44%', '46%'],
              center: ['30%', '60%'],
              type: 'pie',
              label: {
                normal: {
                  show: false
                },
                emphasis: {
                  show: false
                }
              },
              labelLine: {
                normal: {
                  show: false
                },
                emphasis: {
                  show: false
                }
              },
              animation: false,
              tooltip: {
                show: false
              },
              data: [{
                value: 1,
                itemStyle: {
                  color: "#C9EEF2",
                },
              }],
            },
            // 内圆设置
            {
              radius: [0, '45%'],
              center: ['30%', '60%'],
              hoverAnimation: false,
              type: 'pie',
              label: {
                normal: {
                  show: false
                },
                emphasis: {
                  show: false
                }
              },
              labelLine: {
                normal: {
                  show: false
                },
                emphasis: {
                  show: false
                }
              },
              animation: false,
              tooltip: {
                show: false
              },
              data: [{
                value: 1,
                itemStyle: {
                  normal: {
                    color: { // 完成的圆环的颜色
                      colorStops: [{
                        offset: 0,
                        color: "#F9FDFE" // 0% 处的颜色
                      }, {
                        offset: 0.4,
                        color: "#E0F6F8" // 100% 处的颜色
                      }]
                    },
                    label: {
                      show: false
                    },
                    labelLine: {
                      show: false
                    }
                  }
                },
              }],
            }
          ]
        }

      myChart.setOption(option);
    },
    getYesterday() {
      let day1 = new Date();
      day1.setTime(day1.getTime()-24*60*60*1000);
      this.currentPreviousDay = day1.getFullYear()+"/" + (day1.getMonth()+1) + "/" + day1.getDate();
    },
    gotoTable() {
      let routeUrl = this.$router.resolve({
        path: "/reports/operation"

      });
      window.open(routeUrl.href, "_blank");
    },
    getRepostData(){
      this.pageLoadFlag = true;
      let data = {
        queryTime	: this.filForm.date,
        cisCode: this.$store.state.user.userInfo.customer.cisCode
      }
      getOperatePlanReach(data).then(res=>{
        //分销网络净增
        this.NetIDN = {}
        //分销网络动销率
        this.DSRDNetwork = {}
        //分销网络单店产出
        this.SingleStoreOutput = {}
        //增量业务(前置渠道)
        this.IncrementalBusiness = {}
        //全渠道口径出货
        this.channelObj = {}
        // 中高端占比
        this.PMHEnd = {}
        // 专供机占比
        this.PDedicatedAircraft = {}
        if(res.data.code == 0) {
          // 商家总数
          this.customerNum =  res.data.data.customerNum ?res.data.data.customerNum: '--'
          // 排名
          this.comprehensiveRanking = res.data.data.comprehensiveRanking ?res.data.data.comprehensiveRanking: '--'
          // 饼图数据
          this.pieData = []
          if(res.data.data.summaryScoreList && res.data.data.summaryScoreList.length > 0){
            res.data.data.summaryScoreList.forEach(item=>{
              item.fullScore = parseInt(item.fullScore)
              this.pieData.push({
                value:item.score,
                name:item.name+'('+item.fullScore+')'
              })
            })
          }  else  {
            this.pieData = [
              {value: 0, name: '全渠道口径出货(60) '},
              {value:0, name: '分销网拓展与维护(20)'},
              {value: 0, name: '增值业务(前置渠道)(5)'},
              {value: 0, name: '销售结构(15) '},
            ]
          }

          // 综合得分
          this.comprehensiveScore = res.data.data.comprehensiveScore ?res.data.data.comprehensiveScore: '--'
          if(res.data.data.individualEvaluations && res.data.data.individualEvaluations.length > 0) {
            res.data.data.individualEvaluations.forEach(item=>{
              if(item.functionName == '分销网络净增') {
                this.NetIDN = item
              }
              if(item.functionName == '分销网络动销率') {
                this.DSRDNetwork = item
              }
              if(item.functionName == '分销网络单店产出') {
                this.SingleStoreOutput = item
              }
              if(item.functionName==' 增量业务(前置渠道)' || item.functionName=='增量业务(前置渠道)') {
                this.IncrementalBusiness = item
              }
              if(item.functionName == '全渠道口径出货') {
                this.channelObj = item
              }
              if(item.functionName == '高中端占比') {
                this.PMHEnd = item
              }
              if(item.functionName == '专供机占比') {
                this.PDedicatedAircraft = item
              }
            })
          }
          this.drawCharts()
          this.pageLoadFlag = false

        }else {
          this.pieData = [
            {value: 0, name: '全渠道口径出货(60)'},
            {value:0, name: '分销网拓展与维护(20)'},
            {value: 0, name: '增值业务(前置渠道)(5)'},
            {value: 0, name: '销售结构(15) '},
          ]
          this.drawCharts()
        }
      }).catch(error=>{
        this.pageLoadFlag = false;
      })
    },


    exportClick() {
      let data = {
        month: this.filForm.date,
        yysCode: this.$store.state.user.userInfo.customer.cisCode
      };
      this.isLoading = true;
      reportUnionAll(data)
        .then(res => {
          this.isLoading = false;
          Util.blobToJson(res.data)
            .then(content => {
              if (content && content.msg == "success") {
                this.$message.success("下载成功，请到下载中心查看!");
              }
            })
            .catch(err => {
              Util.downloadFile(res.data, "综合评价表.xls");
            })
            .finally(() => {
              this.isLoading = false;
            });
        })
        .finally(res => {
          this.isLoading = false;
        });
    },

  }
};
